import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import deleteIconMail from "../../assets/deleteIconMail.svg";
import deleteIconMailRed from "../../assets/deleteIconMailRed.svg";

export const ZohoDeleteIconMail = ({ sx, ...rest }) => {
  const style = {
    margin: "0 0 0 -6.5px",
    height: "12px",
    width: "12px",
    position: "absolute",
  };
  return (
    <IconButton
      aria-label="delete"
      {...rest}
      sx={{
        height: "26px",
        width: "26px",
        m: "1px",
        display: "grid",
        placeItems: "center",
        "&:hover": {
          outline: "1px solid red",
        },
        "& #deletered": {
          opacity: 0,
        },
        "&:hover #deletered": {
          opacity: 1,
        },
      }}
    >
      <Box
        sx={{
          height: "12px",
          width: "12px",
          position: "relative",
        }}
      >
        <Box component={"img"} sx={style} src={deleteIconMail} />
        <Box
          id="deletered"
          component={"img"}
          sx={style}
          src={deleteIconMailRed}
        />
      </Box>
    </IconButton>
  );
};
