import * as React from "react";
import { v4 as uuid } from "uuid";

import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import { EmailHeaderLine } from "../molecules/EmailHeaderLine";
import { EmailToLine } from "../molecules/EmailToLine";
import { EmailCcLine } from "../molecules/EmailCcLine";
import { EmailBccLine } from "../molecules/EmailBccLine";
import { EmailSubjectLine } from "../molecules/EmailSubjectLine";
import { EmailFooterLine } from "../molecules/EmailFooterLine";
import { TipTap } from "../organisms/TipTap";
import { zohoApi } from "../../zohoApi";
import { staticToDynamicEmail, validateEmail } from "../../util/util";
import { editor, settingType } from "../../config/config";
import axios from "axios";

const { useState, useEffect } = React;

const listType = {
  mailmeregeList: {
    title: "Mailmerge",
    type: "mailmeregeList",
  },
  attachments: {
    title: "Attachments",
    type: "attachments",
  },
  fieldFiles: {
    title: "From Record",
    type: "fieldFiles",
  },
  inventory: {
    title: "Inventory Template",
    type: "inventoryTemplate",
  },
};

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// async function staticToDynamicEmail(content, dataArray) {
//   if (content && dataArray) {
//     let temp = content;
//     let fields = content.match(/\$\{[^\r\n}]+\}/g);
//     fields?.forEach((element) => {
//       let elementKey = element?.slice(3).slice(0, -1);

//       let elementData =
//         dataArray?.[elementKey] ||
//         dataArray?.[elementKey + ".Name"] ||
//         dataArray?.[elementKey + ".Full_Name"] ||
//         dataArray?.[elementKey + ".Account_Name"] ||
//         dataArray?.[elementKey + ".Deal_Name"] ||
//         dataArray?.[elementKey + ".Product_Name"] ||
//         dataArray?.[elementKey + ".Campaign_Name"] ||
//         dataArray?.[elementKey + ".Vendor_Name"] ||
//         dataArray?.[elementKey + ".Subject"] ||
//         dataArray?.[elementKey + ".name"];
//       if (elementData) {
//         temp = temp.replace(element, elementData);
//       } else {
//         temp = temp.replace(element, "");
//       }
//     });
//     return temp;
//   } else {
//     return content;
//   }
// }

function getEmails(array, record) {
  let tempArray = [];
  array
    ?.map((el) => el.api_name)
    ?.forEach((api) => {
      if (validateEmail(record[api])) {
        tempArray.push(record[api]);
      }
    });
  return tempArray;
}

export const EmailComponent = ({
  module,
  recordId,
  zapiKey,
  accessToken,
  relatedListModule,
  setRelatedListModule,
  relationshipName,
  setRelationshipName,
  freeSolo,
  noParentRecord,
  handleEmailBack,
  //------
  crmUsersEmail,
  crmUsersEmailLoading,
  //------
  selectedEmailTemplateId,
  setSelectedEmailTemplateId,
  //------
  emailType,
  setEmailType,
  //------
  fromEmail,
  setFromEmail,
  //------
  emailTemplates,
  emailTemplatesLoading,
  //------
  inventoryTemplates,
  inventoryTemplatesLoading,
  selectedInventoryTemplate,
  setSelectedInventoryTemplate,
  //------
  emailOptions,
  storage,
  finalRecords,
  savedModule,
  dataCenterUrl,
  //------
  selectedFallbackEmail,
  setSelectedFallbackEmail,
  isOwnerEmailSelected,
  setIsOwnerEmailSelected,
  //------
  subject,
  setSubject,
  emailContent,
  setEmailContent,
  editorType,
  setEditorType,
  //------
  customActionPage,
  orgId,
  apiKey,
  configdata,
  configSchema: settingSchema,
  setConfigdataLoading,
  inventoryModule,
  adminEmail,
  hideCloseButton,
  handlePreviewOpen,
}) => {
  const [selectedTemplateLoading, setSelectedTemplateLoading] = useState(false);
  const [toArray, setToArray] = useState([]);
  const [ccArray, setCcArray] = useState([]);
  const [bccArray, setBccArray] = useState([]);

  const [cc, setCc] = useState(false);
  const [bcc, setBcc] = useState(false);
  const [fileSelected, setFileSelected] = React.useState(false);
  //-------------
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  //-------------
  const [mailmeregeListLoading, setMailmeregeListLoading] = useState(false);
  const [mailmeregeList, setMailmeregeList] = useState([]);
  const [selectedMailmerege, setSelectedMailmerege] = useState([]);
  //-------------
  const [attachmentsLoading, setAttachmentsLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  //-------------
  const [fieldFilesLoading, setFieldFilesLoading] = useState(false);
  const [fieldFiles, setFieldFiles] = useState([]);
  const [selectedFieldFiles, setSelectedFieldFiles] = useState([]);
  //-------------
  const [templateAttachments, setTemplateAttachments] = useState([]);
  //-------------
  const [waitingSubmitResp, setWaitingSubmitResp] = useState(false);
  const filesRef = React.useRef([]);
  const deletableFilesRef = React.useRef([]);
  const resetTipTapRef = React.useRef();
  const { enqueueSnackbar } = useSnackbar();
  //-------------
  let considerOwnerEmail = isOwnerEmailSelected && selectedFallbackEmail !== "";
  let tempModule = !!relatedListModule ? relatedListModule : module;

  const handleOpenBcc = () => {
    setBcc(true);
  };

  const handleCloseBcc = () => {
    setBcc(false);
  };

  const handleOpenCc = () => {
    setCc(true);
  };

  const handleCloseCc = () => {
    setCc(false);
  };

  const totalFileLength =
    selectedFiles?.length +
    selectedAttachments?.length +
    selectedFieldFiles?.length +
    selectedMailmerege?.length;
  // + selectedInventoryTemplate?.length;

  const dataLoading =
    mailmeregeListLoading || fieldFilesLoading || attachmentsLoading;
  // || inventoryTemplatesLoading;

  const cardArray = [
    {
      arr: selectedFiles,
      file: true,
      onDelete: (name) => {
        setSelectedFiles((pre) => {
          return pre.filter((item) => item.name !== name);
        });
      },
    },
    {
      arr: selectedMailmerege,
      helperText: "Mailmerge",
      onDelete: (name) => {
        setSelectedMailmerege((pre) =>
          pre.filter((item) => item.name !== name)
        );
      },
    },
    {
      arr: selectedFieldFiles,
      helperText: "From Record",
      onDelete: (name) => {
        setSelectedFieldFiles((pre) =>
          pre.filter((item) => item.name !== name)
        );
      },
    },
    {
      arr: selectedAttachments,
      helperText: "Attachments",
      onDelete: (name) => {
        setSelectedAttachments((pre) =>
          pre.filter((item) => item.name !== name)
        );
      },
    },
    // {
    //   arr: selectedInventoryTemplate,
    //   helperText: "Inventory",
    //   onDelete: (name) => {
    //     setSelectedInventoryTemplate((pre) =>
    //       pre.filter((item) => item.name !== name)
    //     );
    //   },
    // },
  ];

  const fetchSingleEmailTemplate = React.useCallback(
    async (templateId) => {
      setSelectedTemplateLoading(true);
      const template = await zohoApi.email.getSingleEmailTemplate({
        templateId,
        accessToken,
        dataCenterUrl,
      });
      setSelectedEmailTemplateId(templateId);
      setSubject(template.data.subject);
      setEmailContent(template.data.content);
      setTemplateAttachments(
        template.data?.attachments?.map((el) => el?.file_id)
      );
      setEditorType(editor.template);
      setSelectedTemplateLoading(false);
    },
    [accessToken, dataCenterUrl]
  );

  const fetchAttachments = async () => {
    setAttachments([]);
    setAttachmentsLoading(true);
    const extraAttachments = deletableFilesRef.current?.map((el) => el.id);
    const attachments = await zohoApi.file.getAttachments({
      module,
      recordId,
      accessToken,
      dataCenterUrl,
    });
    const all = await attachments?.data?.map((el) => ({
      ...el,
      name: el.File_Name,
    }));
    const filetered = await all?.filter(
      (el) => !extraAttachments.includes(el.id)
    );
    setAttachments(filetered);
    setAttachmentsLoading(false);
  };

  const fetchMailmerges = React.useCallback(
    async (paramModule) => {
      if (mailmeregeList?.length === 0) {
        setMailmeregeListLoading(true);
        const mailmeregeList = await zohoApi.mailmerge.getMailmergeTemplateList(
          {
            module: paramModule || tempModule,
            accessToken,
            dataCenterUrl,
          }
        );
        const tem = await mailmeregeList?.data?.map((el) => ({
          id: el,
          name: el,
        }));

        setMailmeregeList(tem);
        setMailmeregeListLoading(false);
      }
    },
    [accessToken, mailmeregeList?.length, tempModule, dataCenterUrl]
  );

  const fetchFieldFiles = React.useCallback(
    async (paramModule) => {
      if (fieldFiles?.length === 0) {
        setFieldFilesLoading(true);
        const { data: fileFields } = await zohoApi.record.getFileFields({
          module: paramModule || tempModule,
          accessToken,
          dataCenterUrl,
        });
        const tem = await fileFields?.map((el) => ({
          ...el,
          name: el.display_label,
        }));
        setFieldFiles(tem);
        setFieldFilesLoading(false);
      }
    },
    [accessToken, fieldFiles?.length, tempModule, dataCenterUrl]
  );

  let firstMM = React.useRef(true);
  let firstFF = React.useRef(true);
  useEffect(() => {
    const fetchSavedConfigDetailsCustomActionPage = async () => {
      const tempModule = settingSchema.relatedListModule
        ? settingSchema.relatedListModule
        : settingSchema.module;
      if (settingSchema.toArrayApis?.length > 0) {
        const tempToArray = emailOptions?.filter((el) =>
          settingSchema?.toArrayApis?.includes(el.api_name)
        );
        const tempToArrayManual = settingSchema?.toArrayApis?.filter((el) =>
          validateEmail(el)
        );
        setToArray([...tempToArray, ...tempToArrayManual]);
      }
      if (settingSchema.ccArrayApis?.length > 0) {
        const tempCcArray = emailOptions?.filter((el) =>
          settingSchema?.ccArrayApis?.includes(el.api_name)
        );
        const tempCcArrayManual = settingSchema?.ccArrayApis?.filter((el) =>
          validateEmail(el)
        );
        setCcArray([...tempCcArray, ...tempCcArrayManual]);
        setCc(true);
      }
      if (settingSchema.bccArrayApis?.length > 0) {
        const tempBccArray = emailOptions?.filter((el) =>
          settingSchema?.bccArrayApis?.includes(el.api_name)
        );
        const tempBccArrayManual = settingSchema?.bccArrayApis?.filter((el) =>
          validateEmail(el)
        );
        setBccArray([...tempBccArray, ...tempBccArrayManual]);
        setBcc(true);
      }
      if (settingSchema.selectedMailmeregeName?.length > 0) {
        if (firstMM.current && accessToken) {
          firstMM.current = false;
          fetchMailmerges(tempModule);
        }
        const tempMMArray = mailmeregeList?.filter((el) =>
          settingSchema?.selectedMailmeregeName?.includes(el.name)
        );
        setSelectedMailmerege(tempMMArray);
      }
      if (settingSchema?.selectedFieldFilesApis?.length > 0) {
        if (firstFF.current && accessToken) {
          firstFF.current = false;
          fetchFieldFiles(tempModule);
        }
        const tempFFArray = fieldFiles?.filter((el) =>
          settingSchema?.selectedFieldFilesApis?.includes(el.api_name)
        );
        setSelectedFieldFiles(tempFFArray);
      }
      setConfigdataLoading(false);
    };
    if (settingSchema && accessToken) {
      fetchSavedConfigDetailsCustomActionPage();
    }
  }, [
    accessToken,
    settingSchema,
    emailOptions,
    mailmeregeList,
    fieldFiles,
    fetchSingleEmailTemplate,
    fetchMailmerges,
    fetchFieldFiles,
    setConfigdataLoading,
  ]);

  const getList = (type) => {
    if (type === listType.mailmeregeList.type) {
      return mailmeregeList;
    }
    if (type === listType.attachments.type) {
      return attachments;
    }
    if (type === listType.fieldFiles.type) {
      return fieldFiles;
    }
    // if (type === listType.inventory.type) {
    //   return inventoryTemplates;
    // }
    return [];
  };

  const getSelectedList = React.useCallback(
    (type) => {
      if (type === listType.mailmeregeList.type) {
        return selectedMailmerege;
      }
      if (type === listType.attachments.type) {
        return selectedAttachments;
      }
      if (type === listType.fieldFiles.type) {
        return selectedFieldFiles;
      }
      // if (type === listType.inventory.type) {
      //   return selectedInventoryTemplate;
      // }
      return [];
    },
    [
      selectedMailmerege,
      selectedAttachments,
      selectedFieldFiles,
      // selectedInventoryTemplate,
    ]
  );

  const handleSelectItem = ({ type, arr }) => {
    if (type === listType.mailmeregeList.type) {
      setSelectedMailmerege(arr);
    }
    if (type === listType.attachments.type) {
      setSelectedAttachments(arr);
    }
    if (type === listType.fieldFiles.type) {
      setSelectedFieldFiles(arr);
    }
    // if (type === listType.inventory.type) {
    //   setSelectedInventoryTemplate(arr);
    // }
    if (arr?.length === 0) {
      setFileSelected(false);
    } else {
      setFileSelected(true);
    }
  };

  const handleSelectFile = async (e) => {
    e.preventDefault();
    if (e.target.files) {
      const distinctFiles = {};
      [...e?.target?.files]?.forEach((item) => {
        distinctFiles[item.name] = item;
      });
      setSelectedFiles((pre) => {
        pre?.forEach((item) => {
          distinctFiles[item.name] = item;
        });
        return Object.values(distinctFiles);
      });
      setFileSelected(true);
      // setOpen(false);
    }
  };

  const checkLength = () => {
    if (totalFileLength === 1) {
      setFileSelected(false);
    }
  };

  /**
   * In this Record,
   */

  const updMailmergeIds = async ({
    module,
    recordId,
    storageModule,
    storageRecordId,
    dataCenterUrl,
    accessToken,
    selectedMailmerege,
  }) => {
    let newIds = [];
    let attachments;
    if (selectedMailmerege?.length > 0) {
      for (let index = 0; index < selectedMailmerege?.length; index++) {
        const el = selectedMailmerege[index];
        const mailmergeResp =
          await zohoApi.mailmerge.mailmergeToAttachmentsByDeluge({
            module,
            recordId,
            storageModule,
            storageRecordId,
            templateName: el.name,
            dataCenterUrl,
            accessToken,
          });
        const id = await mailmergeResp?.data?.details?.id;
        newIds.push(id);
      }

      attachments = await zohoApi.file.getAttachments({
        module: storageModule,
        recordId: storageRecordId,
        accessToken,
        dataCenterUrl,
      });

      const arr = await attachments?.data?.filter((el) =>
        newIds.includes(el.id)
      );
      const fileIdArr = await arr?.map((el) => el.$file_id);
      return { $file_id: fileIdArr, id: newIds };
    }
    return {};
  };

  const updFileIds = async ({
    selectedFiles,
    storageModule,
    storageRecordId,
  }) => {
    let newIds = [];
    let attachments;
    if (selectedFiles?.length > 0) {
      for (let index = 0; index < selectedFiles?.length; index++) {
        const el = selectedFiles[index];
        const fileResp = await zohoApi.file.uploadAttachment({
          module: storageModule,
          recordId: storageRecordId,
          data: el,
        });
        const id = await fileResp?.data?.[0]?.details?.id;
        newIds.push(id);
      }

      attachments = await zohoApi.file.getAttachments({
        module: storageModule,
        recordId: storageRecordId,
        accessToken,
        dataCenterUrl,
      });

      const arr = await attachments?.data?.filter((el) =>
        newIds.includes(el.id)
      );
      const fileIdArr = await arr?.map((el) => el.$file_id);
      return { $file_id: fileIdArr, id: newIds };
    }
    return {};
  };

  const getRecordFileIds = async ({ module, recordId, selectedFieldFiles }) => {
    let newIds = [];
    if (selectedFieldFiles?.length > 0) {
      let fieldArrObj = {};
      let fieldApis = selectedFieldFiles?.map((field) => {
        fieldArrObj[field.api_name] = field;
        return field.api_name;
      });
      const { data: record } = await zohoApi.record.getRecordDetailsRestAPI({
        module,
        recordId,
        accessToken,
        dataCenterUrl,
      });
      Object.keys(record).forEach((key) => {
        if (fieldApis?.includes(key) && record[key]) {
          record[key].forEach((obj) => {
            newIds.push(obj?.File_Id__s);
          });
        }
      });
    }
    return newIds;
  };

  const clearTextEditor = async () => {
    setSubject("");
    setEmailContent("");
    setEditorType(editor.rich_text);
    setSelectedEmailTemplateId(undefined);
  };

  const clearComponentData = async () => {
    setRelatedListModule(undefined);
    setRelationshipName(undefined);
    setIsOwnerEmailSelected(false);
    setSelectedFallbackEmail("");
    setSelectedInventoryTemplate([]);
    setToArray([]);
    setCcArray([]);
    setBccArray([]);
    setCc(false);
    setBcc(false);
    setSelectedMailmerege([]);
    setSelectedFieldFiles([]);
    setSelectedAttachments([]);
    setSelectedFiles([]);
  };

  const deleteTempFiles = async ({ storageModule, storageRecordId }) => {
    if (deletableFilesRef.current?.length > 0) {
      for (let index = 0; index < deletableFilesRef.current?.length; index++) {
        const elementId = deletableFilesRef.current[index];
        const del = await zohoApi.file.deleteAttachment({
          module: storageModule,
          recordId: storageRecordId,
          attachment_id: elementId,
          accessToken,
          dataCenterUrl,
        });
      }
    }
    deletableFilesRef.current = [];
    filesRef.current = [];
  };

  const getPCFileIds = async ({
    selectedFiles,
    storageModule,
    storageRecordId,
  }) => {
    const resp_pc = await updFileIds({
      selectedFiles,
      storageModule,
      storageRecordId,
    });
    const $file_id_pc = await resp_pc?.$file_id;
    const id_pc = await resp_pc?.id;
    // deletableFilesRef.current.push(...(id_pc || []));
    //------------------
    const $file_id_at = selectedAttachments?.map((el) => el?.$file_id);

    return [...($file_id_pc || []), ...($file_id_at || [])];
  };

  const onSubmit = async (e) => {
    //1.  UUID Generate
    const settingId = uuid();
    e.preventDefault();
    let tempFromEmail;
    let signature;

    if (!considerOwnerEmail) {
      tempFromEmail = fromEmail;
      signature = await zohoApi.user.getCrmUsersSignature({
        email: tempFromEmail,
      });
    }

    if (finalRecords) {
      setWaitingSubmitResp(true);
      //------------------
      // const fileIds = await getPCFileIds({
      //   selectedFiles,
      //   storageModule: storage.module,
      //   storageRecordId: storage.recordId,
      // });
      //------------------ Desktop File
      const finalRecordsCount = finalRecords.length || 0;

      //------------------
      for (const record of finalRecords) {
        const schema = {
          fromEmail,
          subject,
          emailContent,
          module,
          relatedListModule,
          relationshipName,
          ownerEmailSelected: isOwnerEmailSelected,
          fallbackEmail: selectedFallbackEmail,
          selectedEmailTemplateId,
          selectedInventoryTemplate,
          toArrayApis:
            finalRecords?.length === 1 ? toArray : getEmails(toArray, record),
          ccArrayApis:
            finalRecords?.length === 1 ? ccArray : getEmails(ccArray, record),
          bccArrayApis:
            finalRecords?.length === 1 ? bccArray : getEmails(bccArray, record),
          selectedMailmeregeName: selectedMailmerege?.map((el) => el.name),
          selectedFieldFilesApis: selectedFieldFiles?.map((el) => el.api_name),
          emailType,
          templateAttachments,
        };

        if (finalRecordsCount > 1) {
          const manualToArray = toArray?.filter((el) =>
            validateEmail(el) ? true : false
          );
          const manualCcArray = ccArray?.filter((el) =>
            validateEmail(el) ? true : false
          );
          const manualBccArray = bccArray?.filter((el) =>
            validateEmail(el) ? true : false
          );
          schema.toArrayApis = [...schema?.toArrayApis, ...manualToArray];
          schema.ccArrayApis = [...schema?.ccArrayApis, ...manualCcArray];
          schema.bccArrayApis = [...schema?.bccArrayApis, ...manualBccArray];
          // schema.manualToArray = manualToArray;
          // schema.manualCcArray = manualCcArray;
          // schema.manualBccArray = manualBccArray;
          const newLogResp = await zohoApi.record.insertRecord({
            module: "easyemailforzohocrm__Email_Logs",
            recordData: {
              easyemailforzohocrm__Setting_ID: settingId, //
              Name: record?.id,
              easyemailforzohocrm__Module_Record_ID: record?.id,
              easyemailforzohocrm__Setting_Schema: schema,
              easyemailforzohocrm__From_Email: fromEmail,
              easyemailforzohocrm__Module_Name: module,
              easyemailforzohocrm__To_Emails:
                finalRecords?.length === 1
                  ? JSON.stringify(toArray)
                  : JSON.stringify([
                      ...getEmails(toArray, record),
                      ...manualToArray,
                    ]),
              easyemailforzohocrm__CC_Emails:
                finalRecords?.length === 1
                  ? JSON.stringify(ccArray)
                  : JSON.stringify([
                      ...getEmails(ccArray, record),
                      ...manualCcArray,
                    ]),
              easyemailforzohocrm__BCC_Emails:
                finalRecords?.length === 1
                  ? JSON.stringify(bccArray)
                  : JSON.stringify([
                      ...getEmails(bccArray, record),
                      ...manualBccArray,
                    ]),
              easyemailforzohocrm__Status: "Pending",
              easyemailforzohocrm__Status_Message: "",
              easyemailforzohocrm__Response_JSON: "",
            },
          });

          await getPCFileIds({
            selectedFiles,
            storageModule: "easyemailforzohocrm__Email_Logs",
            storageRecordId: newLogResp?.data?.[0]?.details?.id,
          });
        }

        let tempRecordId = record.id;
        if (finalRecordsCount === 1) {
          const resp_pc = await updFileIds({
            selectedFiles,
            storageModule: storage.module,
            storageRecordId: storage.recordId,
          });
          const $file_id_pc = await resp_pc?.$file_id;
          const id_pc = await resp_pc?.id;
          deletableFilesRef.current.push(...(id_pc || []));
          //------------------
          const $file_id_at = selectedAttachments?.map((el) => el?.$file_id);
          //------------------ From File Field
          let file_id_record = await getRecordFileIds({
            module: tempModule,
            recordId: tempRecordId,
            selectedFieldFiles,
          });
          //------------------ Mailmerge
          const resp_mm = await updMailmergeIds({
            module: tempModule,
            recordId: tempRecordId,
            storageModule: storage.module,
            storageRecordId: storage.recordId,
            dataCenterUrl,
            accessToken,
            selectedMailmerege,
          });
          const $file_id_mm = await resp_mm?.$file_id;
          const id_mm = await resp_mm?.id;
          deletableFilesRef.current.push(...(id_mm || []));

          //------------------
          let moduleFieldsAndData;

          if (selectedEmailTemplateId) {
            moduleFieldsAndData = await zohoApi.record.getFieldsAndData({
              entity: tempModule,
              entityId: tempRecordId,
            });
          }

          if (considerOwnerEmail) {
            const ownerEmail = record?.Owner?.email;
            tempFromEmail = crmUsersEmail.includes(ownerEmail)
              ? ownerEmail
              : selectedFallbackEmail;
            signature = await zohoApi.user.getCrmUsersSignature({
              email: tempFromEmail,
            });
          }

          let tempContent = emailContent.replace(
            "${!userSignature}",
            signature || ""
          );

          let mailObj = {
            module: tempModule,
            recordId: tempRecordId,
            fromEmail: tempFromEmail,
            toArray:
              finalRecords?.length === 1 ? toArray : getEmails(toArray, record),
            ccArray:
              finalRecords?.length === 1 ? ccArray : getEmails(ccArray, record),
            bccArray:
              finalRecords?.length === 1
                ? bccArray
                : getEmails(bccArray, record),
            subject: await staticToDynamicEmail(
              subject,
              moduleFieldsAndData?.recordData
            ),
            content: await staticToDynamicEmail(
              tempContent,
              moduleFieldsAndData?.recordData
            ),
            attachments: [
              // ...fileIds,
              ...($file_id_pc || []),
              // ...recordFileIds,
              ...(file_id_record || []),
              ...($file_id_mm || []),
              ...($file_id_at || []),
              ...(templateAttachments || []),
            ],
            inventory_template: selectedInventoryTemplate,
            accessToken,
            dataCenterUrl,
            emailType,
          };

          //--------------------
          const { data: mailResp } = await zohoApi.email.sendEmail(mailObj);

          //--------------------

          // const mailResp = {
          //   status: null,
          //   message: "it is just placeholder mail resp.",
          // };
          //--------------------
          await zohoApi.record.insertRecord({
            module: "easyemailforzohocrm__Email_Logs",
            recordData: {
              easyemailforzohocrm__Setting_ID: settingId, //
              Name: record?.id,
              easyemailforzohocrm__Module_Record_ID: record?.id,
              easyemailforzohocrm__Setting_Schema: schema,
              easyemailforzohocrm__From_Email: fromEmail,
              easyemailforzohocrm__Module_Name: module,
              easyemailforzohocrm__To_Emails:
                finalRecords?.length === 1
                  ? JSON.stringify(toArray)
                  : JSON.stringify(getEmails(toArray, record)),
              easyemailforzohocrm__CC_Emails:
                finalRecords?.length === 1
                  ? JSON.stringify(ccArray)
                  : JSON.stringify(getEmails(ccArray, record)),
              easyemailforzohocrm__BCC_Emails:
                finalRecords?.length === 1
                  ? JSON.stringify(bccArray)
                  : JSON.stringify(getEmails(bccArray, record)),
              easyemailforzohocrm__Status:
                mailResp?.status === "success" ? "Sent" : "Error",
              easyemailforzohocrm__Status_Message: mailResp?.message,
              easyemailforzohocrm__Response_JSON: mailResp,
            },
          });
          if (mailResp?.status === "success") {
            enqueueSnackbar(`Email sent successfully`, {
              variant: "success",
              autoHideDuration: 3000,
            });
          } else {
            enqueueSnackbar(mailResp?.message, {
              variant: "error",
              autoHideDuration: 3000,
            });
          }
        }
      }

      // For Multiple
      if (finalRecordsCount > 1) {
        // todo Create CRON with Setting ID/UUID in the Rest API URL
        const cronFetchUrl = `${dataCenterUrl}/crm/v2/functions/easyemailforzohocrm__sendpendingemails/actions/execute?auth_type=apikey&zapikey=${zapiKey}&setting_id=${settingId}`;
        const data = {
          url: cronFetchUrl,
          name: settingId,
          params: {},
          headers: {
            orgid: orgId,
            apikey: apiKey,
            connname: "easyemailforzohocrm",
            "content-type": "application/json",
            datacenterurl: dataCenterUrl,
          },
          body: {},
          type: "GET",
          execute_in: 1,
        };

        // {"url":crm_url,"name":title,"headers":Map(),"body":Map(),"params":Map(),"type":"GET","execute_in":1}

        try {
          const base_url = process.env.REACT_APP_ADMIN_SERVER_URL;
          const createCron = await axios({
            url: base_url + "/utils/cronjoborg",
            method: "post",
            headers: {
              orgid: orgId,
              connname: "easyemailforzohocrm",
              apikey: apiKey,
              datacenterurl: dataCenterUrl,
            },
            data: data,
          });
        } catch (error) {}

        enqueueSnackbar(
          `Emails are scheduled, please check Log module for Status`,
          {
            variant: "success",
            autoHideDuration: 3000,
          }
        );
      }
    }

    await deleteTempFiles({
      storageModule: storage.module,
      storageRecordId: storage.recordId,
    });

    await clearTextEditor();
    await clearComponentData();
    setFileSelected(false);
    resetTipTapRef?.current?.();

    setWaitingSubmitResp(false);
    await delay(1000);
    await window.ZOHO.CRM.UI.Popup.closeReload();
  };

  const onCAPS = async (e) => {
    e.preventDefault();

    try {
      setWaitingSubmitResp(true);
      const settingSchema = {
        fromEmail,
        subject,
        emailContent,
        module,
        relatedListModule,
        relationshipName,
        ownerEmailSelected: isOwnerEmailSelected,
        fallbackEmail: selectedFallbackEmail,
        selectedEmailTemplateId,
        selectedInventoryTemplate,
        toArrayApis: toArray?.map((el) => el?.api_name || el),
        ccArrayApis: ccArray?.map((el) => el?.api_name || el),
        bccArrayApis: bccArray?.map((el) => el?.api_name || el),
        selectedMailmeregeName: selectedMailmerege?.map((el) => el.name),
        selectedFieldFilesApis: selectedFieldFiles?.map((el) => el.api_name),
        emailType,
        templateAttachments,
      };

      const config = {
        orgId,
        dataCenterUrl,
        settingType: settingType.CustomAction,
        moduleApiName: module,
        settingId: configdata?.settingId || "",
      };
      //----
      const dbConfig = { ...config, apiKey, settingSchema };
      const dbConfigResp = await zohoApi.config.upsertConfigToDB(dbConfig);
      const zohoConfig = {
        ...config,
        settingId: dbConfigResp?.data?.data?.setting_id,
      };
      const noResp = await zohoApi.config.saveConfigToZoho(zohoConfig);
      //----
      setWaitingSubmitResp(false);
    } catch (onCAPSError) {}
  };

  return (
    <Box
      component={"form"}
      onSubmit={customActionPage ? onCAPS : onSubmit}
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <EmailHeaderLine
        emailTemplates={emailTemplates}
        emailTemplatesLoading={emailTemplatesLoading}
        selectedTemplateLoading={selectedTemplateLoading}
        fetchSingleEmailTemplate={fetchSingleEmailTemplate}
        //------
        crmUsersEmail={crmUsersEmail}
        crmUsersEmailLoading={crmUsersEmailLoading}
        //------
        fromEmail={fromEmail}
        setFromEmail={setFromEmail}
        //------
        inventoryTemplates={inventoryTemplates}
        inventoryTemplatesLoading={inventoryTemplatesLoading}
        inventoryModule={inventoryModule}
        selectedInventoryTemplate={selectedInventoryTemplate}
        setSelectedInventoryTemplate={setSelectedInventoryTemplate}
        //------
        isOwnerEmailSelected={isOwnerEmailSelected}
        setIsOwnerEmailSelected={setIsOwnerEmailSelected}
        selectedFallbackEmail={selectedFallbackEmail}
        setSelectedFallbackEmail={setSelectedFallbackEmail}
        setEmailType={setEmailType}
        adminEmail={adminEmail}
      />
      <EmailToLine
        freeSolo={freeSolo}
        cc={cc}
        bcc={bcc}
        handleOpenBcc={handleOpenBcc}
        handleOpenCc={handleOpenCc}
        options={emailOptions}
        array={toArray}
        setArray={setToArray}
      />
      {cc ? (
        <EmailCcLine
          freeSolo={freeSolo}
          handleCloseCc={handleCloseCc}
          options={emailOptions}
          array={ccArray}
          setArray={setCcArray}
        />
      ) : null}
      {bcc ? (
        <EmailBccLine
          freeSolo={freeSolo}
          handleCloseBcc={handleCloseBcc}
          options={emailOptions}
          array={bccArray}
          setArray={setBccArray}
        />
      ) : null}
      <EmailSubjectLine
        onChange={(e) => {
          setSubject(e.target.value);
        }}
        value={subject}
        richTextEditor={editorType === editor.rich_text}
        onSelectRichTextEditor={() => {
          clearTextEditor();
          resetTipTapRef?.current?.();
        }}
      />
      {editorType === editor.rich_text ? (
        <TipTap
          content={emailContent}
          setEmailContent={setEmailContent}
          ref={resetTipTapRef}
        />
      ) : null}
      {editorType === editor.template ? (
        <Box
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            borderTop: "1px solid #d7e2ed",
            position: "relative",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              inset: ".5em",
              overflowY: "auto",
            }}
          >
            <Box
              dangerouslySetInnerHTML={{ __html: emailContent }}
              contentEditable
              onBlur={(e) => {
                setEmailContent(e.currentTarget.innerHTML);
              }}
            />
          </Box>
        </Box>
      ) : null}
      <EmailFooterLine
        diasableSend={
          !emailContent ||
          !subject ||
          !(toArray?.length > 0) ||
          waitingSubmitResp ||
          (isOwnerEmailSelected && selectedFallbackEmail === "") ||
          !fromEmail
        }
        listType={listType}
        setFileSelected={setFileSelected}
        fileSelected={fileSelected}
        handleSelectFile={handleSelectFile}
        //-------------
        fetchMailmerges={fetchMailmerges}
        fetchFieldFiles={fetchFieldFiles}
        fetchAttachments={fetchAttachments}
        //-------------
        checkLength={checkLength}
        totalFileLength={totalFileLength}
        dataLoading={dataLoading}
        cardArray={cardArray}
        handleSelectItem={handleSelectItem}
        handleEmailBack={() => {
          handleEmailBack();
          clearTextEditor();
          clearComponentData();
        }}
        showAttachmentButton={!noParentRecord}
        showDesktopFileButton={!customActionPage}
        getList={getList}
        getSelectedList={getSelectedList}
        customActionPage={customActionPage}
        ctaButtonText={customActionPage ? "Save" : "Send"}
        ctaButtonInLoadingState={waitingSubmitResp}
        inventoryModule={inventoryModule}
        inventoryModuleNoFile={
          inventoryModule && selectedInventoryTemplate?.length < 1
        }
        hideCloseButton={hideCloseButton}
        //-------------
        disablePreview={!selectedEmailTemplateId}
        handlePreviewOpen={handlePreviewOpen}
      />
    </Box>
  );
};
