import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { ZohoDeleteIconMail } from "../atoms/ZohoDeleteIconMail";
import { ZohoCTAButton } from "../atoms/ZohoCTAButton";
import { EmailLineContainer } from "../atoms/EmailLineContainer";
import { ZohoEmailFileButton } from "../atoms/ZohoEmailFileButton";
import { fixedLengthStr } from "../../util/util";

function formatFileSize(bytes, decimalPoint) {
  if (bytes === 0) return "0 Bytes";
  let k = 1024,
    dm = decimalPoint || 0,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const FileCard = ({ name, helperText, onDelete }) => {
  return (
    <Box
      sx={{
        width: "calc((100% - 42px)/3)",
        maxWidth: "300px",
        height: "66px",
        bgcolor: "white",
        borderRadius: "6px",
        border: "1px solid #d7e2ed",
        display: "flex",
      }}
    >
      <Box sx={{ flexGrow: 1, display: "grid", placeItems: "center" }}>
        <InsertDriveFileIcon
          sx={{ width: "1.1rem", color: "rgba(0,0,0,0.77)" }}
        />
      </Box>
      <Box
        sx={{
          flexGrow: 8,
          maxWidth: "175px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{ fontWeight: 600, fontSize: "14px", color: "rgba(0,0,0, 0.77)" }}
        >
          {fixedLengthStr(name)}
        </Typography>
        <Typography sx={{ fontSize: "12px", color: "rgba(0,0,0, 0.77)" }}>
          {helperText}
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1, display: "grid", placeItems: "center" }}>
        <ZohoDeleteIconMail onClick={onDelete} />
      </Box>
    </Box>
  );
};

export const EmailFooterLine = ({
  sx,
  fileSelected,
  setFileSelected,
  handleSelectFile,
  //-------------
  fetchMailmerges,
  fetchFieldFiles,
  fetchAttachments,
  //-------------
  handleSelectItem,
  listType,
  checkLength,
  totalFileLength,
  diasableSend,
  handleEmailBack,
  showAttachmentButton,
  showDesktopFileButton,
  getList,
  getSelectedList,
  ctaButtonText,
  ctaButtonInLoadingState,
  inventoryModule,
  inventoryModuleNoFile,
  dataLoading,
  cardArray,
  hideCloseButton,
  disablePreview,
  handlePreviewOpen,
}) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Slide
        direction="up"
        timeout={{ enter: 300, exit: 300 }}
        in={fileSelected}
      >
        <Box
          sx={{
            borderRadius: "10px 10px 0 0",
            padding: "15px 15px 12px 20px",
            width: "100%",
            position: "absolute",
            bottom: "100%",
            bgcolor: "#f5f7f9",
            border: "1px solid #d7e2ed",
            borderBottom: 0,
            boxShadow: "0 -3px 10px #375a8f17",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: "15px",
            }}
          >
            <Typography sx={{ color: "#313949" }}>Attachments</Typography>
            <IconButton
              onClick={() => {
                setFileSelected(false);
              }}
              aria-label="KeyboardArrowDownIcon"
              sx={{
                bgcolor: "#E1E6EC",
                borderRadius: "6px",
                height: "24px",
                width: "24px",
                "&:hover": {
                  border: "1px solid #d7e2ed",
                  bgcolor: "white",
                },
              }}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "15px",
              flexWrap: "wrap",
              height: "68px",
              overflowY: "auto",
            }}
          >
            {cardArray?.map(({ arr, file, helperText, onDelete }) => {
              return arr?.map((obj, index) => {
                const { name, size } = obj;
                return (
                  <FileCard
                    key={name}
                    name={name}
                    helperText={file ? formatFileSize(size) : helperText}
                    onDelete={() => {
                      checkLength();
                      onDelete(name);
                    }}
                  />
                );
              });
            })}
          </Box>
        </Box>
      </Slide>
      <EmailLineContainer
        sx={{
          display: "flex",
          justifyContent: "space-between",
          bgcolor: "white",
          position: "relative",
          ...sx,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
          <ZohoEmailFileButton
            listType={listType}
            handleSelectFile={handleSelectFile}
            //-------------
            fetchMailmerges={fetchMailmerges}
            fetchFieldFiles={fetchFieldFiles}
            fetchAttachments={fetchAttachments}
            //-------------
            handleSelectItem={handleSelectItem}
            showAttachmentButton={showAttachmentButton}
            showDesktopFileButton={showDesktopFileButton}
            disabled={diasableSend}
            getList={getList}
            getSelectedList={getSelectedList}
            isSpecialModule={inventoryModule}
            dataLoading={dataLoading}
          />

          {totalFileLength > 0 ? (
            <Button
              disableRipple
              onClick={() => {
                setFileSelected((pre) => !pre);
              }}
              sx={{
                color: "#338cf0",
                fontSize: "13px",
                bgcolor: "#e7f2ff",
                borderRadius: "6px",
                textTransform: "capitalize",
                "&:hover": { bgcolor: "#e7f2ff" },
              }}
            >
              {totalFileLength}{" "}
              {totalFileLength === 1 ? "Attachment" : "Attachments"}
            </Button>
          ) : null}
        </Box>
        <Box>
          <Button
            disabled={disablePreview}
            sx={{
              fontSize: "medium",
              fontWeight: "bold",
              textTransform: "capitalize",
              py: 0.15,
              px: "10px",
              mr: ".5em",
            }}
            variant="outlined"
            // color="error"
            // onClick={() => {

            // }}
            onClick={handlePreviewOpen}
          >
            Preview
          </Button>

          <Button
            disabled={ctaButtonInLoadingState}
            sx={{
              fontSize: "medium",
              fontWeight: "bold",
              textTransform: "capitalize",
              py: 0.15,
              px: "10px",
              mr: ".5em",
              height: "100%",
            }}
            variant="outlined"
            disableRipple
            onClick={handleEmailBack}
          >
            <ArrowBackIcon fontSize="inherit" />
          </Button>
          {hideCloseButton ? null : (
            <Button
              disabled={ctaButtonInLoadingState}
              sx={{
                fontSize: "medium",
                fontWeight: "bold",
                textTransform: "capitalize",
                py: 0.15,
                px: "10px",
                mr: ".5em",
              }}
              variant="outlined"
              color="error"
              onClick={async () => {
                window.ZOHO.CRM.UI.Popup.close().then(function (data) {});
              }}
            >
              Close
            </Button>
          )}

          <ZohoCTAButton
            type="submit"
            loading={ctaButtonInLoadingState}
            disabled={
              diasableSend || ctaButtonInLoadingState || inventoryModuleNoFile
            }
          >
            {ctaButtonText}
          </ZohoCTAButton>
        </Box>
      </EmailLineContainer>
    </Box>
  );
};
