import { auth } from "./auth";
import { email } from "./email";
import { file } from "./file";
import { mailmerge } from "./mailmerge";
import { record } from "./record";
import { user } from "./user";
import { config } from "./config";
import { inventory } from "./inventory";

export const zohoApi = {
  auth,
  email,
  file,
  mailmerge,
  record,
  user,
  config,
  inventory,
};
