import * as React from "react";
import Box from "@mui/material/Box";

export const EmailLineContainer = ({ children, sx }) => {
  return (
    <Box
      sx={{
        p: "6px 20px",
        borderTop: "1px solid #d7e2ed",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
