import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

export function DropdownButton({ buttonArray, tooltip }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [btn, setBtn] = React.useState(buttonArray[0]);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          p: "6px",
          pl: "8px",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          "&:hover": { bgcolor: "#e7f2ff" },
        }}
      >
        <Tooltip title={tooltip} placement="bottom-start">
          <span>
            <button
              type="button"
              onClick={() => btn?.onClick?.()}
              style={{
                border: "none",
                backgroundColor: "transparent",
              }}
            >
              {btn?.icon}
            </button>
          </span>
        </Tooltip>

        <ArrowDropDown
          sx={{
            height: "1.1rem",
            width: "1.1rem",
            color: "rgba(0,0,0,0.57)",
          }}
          onClick={handleClick}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        />
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        // MenuListProps={sx:{bgcolor:"red"}}
        // PopoverClasses={{ bgcolor: "red" }}
        sx={{ "& .MuiMenu-list": { p: 0 } }}
      >
        {buttonArray?.map((button) => (
          <MenuItem
            key={button.id}
            onClick={() => {
              setBtn({ id: button.id, icon: button.icon });
              button.onClick();
              handleClose();
            }}
            sx={{
              "&:hover": { bgcolor: "#e7f2ff" },
            }}
          >
            {button.icon}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
