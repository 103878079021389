import * as React from "react";
import Button from "@mui/material/Button";
import AttachmentIcon from "@mui/icons-material/Attachment";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import SnippetFolderOutlinedIcon from "@mui/icons-material/SnippetFolderOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import { Pop } from "../molecules/Pop";
import { PopDialog } from "../molecules/PopDialog";

const attachmentButtonStyle = {
  position: "relative",
  height: "32px",
  width: "32px",
  minWidth: 0,
  p: 0,
  borderRadius: "6px",
  overflow: "hidden",
  bgcolor: "#338cf0",
  boxShadow: "none",
  "&:hover": {
    bgcolor: "#e7f2ff",
    boxShadow: "none",
  },
  "& svg": {
    position: "absolute",
    top: "19%",
    left: "19%",
    height: "20px",
    width: "20px",
    transform: "rotateX(180deg) rotate(-135deg) ",
    color: "white",
  },
  "&:hover svg": {
    color: "#338cf0",
  },
};

const iconStyle = { mr: "1rem", height: "18px", width: "18px" };

export function ZohoEmailFileButton({
  listType,
  handleSelectFile,
  //-------------
  fetchMailmerges,
  fetchFieldFiles,
  fetchAttachments,
  //-------------
  handleSelectItem,
  disabled,
  showAttachmentButton,
  showDesktopFileButton,
  getList,
  getSelectedList,
  dataLoading,
}) {
  const [openPop, setOpenPop] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [selectedType, setSelectedType] = React.useState(null);
  const [emptyDataMessage, setEmptyDataMessage] = React.useState(null);
  const anchorRef = React.useRef(null);
  let [searchInput, setSearchInput] = React.useState("");

  let itemList = getList(selectedType?.type)
    ?.flat()
    ?.filter((obj) =>
      obj.name.toLowerCase().includes(searchInput.toString().toLowerCase())
    );

  const buttonList = [
    {
      icon: <DesktopWindowsOutlinedIcon sx={iconStyle} />,
      typeObj: {
        title: "Desktop",
        type: null,
      },
      optionClick: () => {
        setIsDialogOpen(false);
        setOpenPop(false);
        setSelectedType(null);
        setEmptyDataMessage(null);
        setSearchInput("");
      },
      file: true,
      show: showDesktopFileButton,
    },
    {
      icon: <SnippetFolderOutlinedIcon sx={iconStyle} />,
      typeObj: listType.mailmeregeList,
      optionClick: () => {
        setIsDialogOpen(true);
        setOpenPop(false);
        setSelectedType(listType.mailmeregeList);
        setEmptyDataMessage("Empty mailmerege list.");
        fetchMailmerges();
        setSearchInput("");
      },
      show: true,
    },
    {
      icon: <DescriptionOutlinedIcon sx={iconStyle} />,
      typeObj: listType.fieldFiles,
      optionClick: () => {
        setIsDialogOpen(true);
        setOpenPop(false);
        setSelectedType(listType.fieldFiles);
        setEmptyDataMessage("Empty FieldFiles.");
        fetchFieldFiles();
        setSearchInput("");
      },
      show: true,
    },
    {
      icon: <DescriptionOutlinedIcon sx={iconStyle} />,
      typeObj: listType.attachments,
      optionClick: () => {
        setIsDialogOpen(true);
        setOpenPop(false);
        setSelectedType(listType.attachments);
        setEmptyDataMessage("No attachment.");
        fetchAttachments();
        setSearchInput("");
      },
      show: showAttachmentButton,
    },
  ];

  const handleToggle = () => {
    setOpenPop((prevOpen) => !prevOpen);
    setSelectedType(null);
  };

  const handleClosePop = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenPop(false);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        disabled={disabled}
        ref={anchorRef}
        size="small"
        aria-controls={openPop ? "split-button-menu" : undefined}
        aria-expanded={openPop ? "true" : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={handleToggle}
        variant="contained"
        sx={attachmentButtonStyle}
        type="button"
      >
        <AttachmentIcon />
      </Button>
      <Pop
        anchorRef={anchorRef}
        openPop={openPop}
        handleClosePop={handleClosePop}
        buttonList={buttonList}
        handleSelectFile={(e) => {
          handleSelectFile(e);
          setOpenPop(false);
        }}
      />
      <PopDialog
        isDialogOpen={isDialogOpen}
        handleCloseDialog={handleCloseDialog}
        selectedType={selectedType}
        loading={dataLoading}
        itemList={itemList}
        noDataMessage={emptyDataMessage}
        handleSelectItem={handleSelectItem}
        selectedItemList={getSelectedList(selectedType?.type)}
        setSearchInput={setSearchInput}
      />
    </React.Fragment>
  );
}
