import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { useZohoAuth } from "../hook/useZohoAuth";

export function SettingsRoute() {
  const {
    authLoadingZoho,
    zohoAuthenticated,
    handleRevokeAuth,
    handleGrantAuth,
  } = useZohoAuth({ checkZohoAuth: true });

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
      }}
    >
      <Box sx={{ p: "12px 16px" }}>
        <Box
          sx={{
            p: "2em",
            borderRadius: "8px",
            maxWidth: "800px",
            // margin: "0 auto",
          }}
        >
          <Typography
            sx={{
              mb: "1em",
              color: "rgba(32,33,35,.9)",
              fontWeight: 700,
            }}
          >
            Authenticate ZOHO CRM and Writer Services for API Access
          </Typography>
          <Typography
            sx={{
              color: "rgba(32,33,35,.39)",
              fontSize: "14px",
              mb: "1.5em",
            }}
          >
            To effectively utilize the Zoho CRM REST API for sending emails or
            performing other actions, you'll need to authenticate your
            application with Zoho. This ensures secure access to your CRM and
            Writer data and enables the API to perform tasks on your behalf.
          </Typography>
          {zohoAuthenticated ? (
            <Button
              onClick={handleRevokeAuth}
              variant="contained"
              sx={{
                textTransform: "capitalize",
                width: "180px",
                "-webkit-justify-content": "left",
                justifyContent: "left",
              }}
            >
              {authLoadingZoho ? "Loading..." : "Revoke"}
            </Button>
          ) : (
            <Button
              onClick={handleGrantAuth}
              variant="contained"
              sx={{
                textTransform: "capitalize",
                width: "180px",
                "-webkit-justify-content": "left",
                justifyContent: "left",
              }}
            >
              {authLoadingZoho ? "Loading..." : "Authenticate"}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
