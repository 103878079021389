export const fixedLengthStr = (name, length = 17) => {
  let newLabel;
  if (name?.length > length) {
    const firstNch = name.slice(0, length - 9);
    const fileExtension = "." + name.split(".").slice(-1);
    const letterBeforeFileExtension = name.split(".").slice(-2)[0].slice(-1);
    newLabel = firstNch + "..." + letterBeforeFileExtension + fileExtension;
  } else {
    newLabel = name;
  }
  return newLabel;
};

export const validateEmail = (email) => {
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return emailRegex.test(email);
};

export async function staticToDynamicEmail(content, dataArray) {
  if (content && dataArray) {
    let temp = content;
    let fields = content.match(/\$\{[^\r\n}]+\}/g);
    fields?.forEach((element) => {
      let elementKey = element?.slice(3).slice(0, -1);

      let elementData =
        dataArray?.[elementKey] ||
        dataArray?.[elementKey + ".Name"] ||
        dataArray?.[elementKey + ".Full_Name"] ||
        dataArray?.[elementKey + ".Account_Name"] ||
        dataArray?.[elementKey + ".Deal_Name"] ||
        dataArray?.[elementKey + ".Product_Name"] ||
        dataArray?.[elementKey + ".Campaign_Name"] ||
        dataArray?.[elementKey + ".Vendor_Name"] ||
        dataArray?.[elementKey + ".Subject"] ||
        dataArray?.[elementKey + ".name"];
      if (elementData) {
        temp = temp.replace(element, elementData);
      } else {
        temp = temp.replace(element, "");
      }
    });
    return temp;
  } else {
    return content;
  }
}
