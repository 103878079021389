import React, { forwardRef } from "react";
import StarterKit from "@tiptap/starter-kit";
import Highlight from "@tiptap/extension-highlight";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import Blockquote from "@tiptap/extension-blockquote";
import { EditorContent, useEditor } from "@tiptap/react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import Crop32Icon from "@mui/icons-material/Crop32";
import CodeIcon from "@mui/icons-material/Code";
import RemoveIcon from "@mui/icons-material/Remove";
import GridOnIcon from "@mui/icons-material/GridOn";
import FormatClearIcon from "@mui/icons-material/FormatClear";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import { DropdownButton } from "../atoms/DropdownButton";
import { EmailLineContainer } from "../atoms/EmailLineContainer";

const outerMailOpen = `<!DOCTYPE html>
<html lang='en' xmlns='http://www.w3.org/1999/xhtml' xmlns:o='urn:schemas-microsoft-com:office:office'>
<head>
	<meta charset='utf-8'>
  <meta name='viewport' content='width=device-width,initial-scale=1'>
  <meta name='x-apple-disable-message-reformatting'>
  <!--[if !mso]><!-->
	  <meta http-equiv='X-UA-Compatible' content='IE=edge' />
	<!--<![endif]-->
  <title></title>
	<!--[if mso]>
	<style type='text/css'>
    table {border-collapse:collapse;border:0;border-spacing:0;margin:0;}
    div, td {padding:0;}
    div {margin:0 !important;}
	</style>
  <noscript>
    <xml>
      <o:OfficeDocumentSettings>
        <o:PixelsPerInch>96</o:PixelsPerInch>
      </o:OfficeDocumentSettings>
    </xml>
  </noscript>
  <![endif]-->
  <style type='text/css'>
    body[data-outlook-cycle] .image{
  width:300px;
}

    @media screen and (max-width: 410px) {
      .two-col .column-left {
        max-width: 100% !important;
      }
      .two-col img {
        /* width: 100% !important; */
      }
       .two-col .column {
        max-width: 50% !important;
      }
    }
    @media screen and (min-width: 411px) {
      .two-col .column {
        max-width: 50% !important;
      }
      .two-col .column-left {
        max-width: 33.33% !important;
      }
      .two-col .column-right {
        max-width: 66.66% !important;
      }
    }
  </style>
</head>
<body style='margin:0;padding:0;word-spacing:normal;background-color:#ffffff;'>
  <div role='article' aria-roledescription='email' lang='en' style='-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;'>
    <table role='presentation' style='width:100%;border:0;border-spacing:0;'>
      <tr>
        <td align='center'>
          <!--[if mso]>
          <table role='presentation' align='center' style='width:660px;'>
          <tr>
          <td style='padding:20px 0;'>
          <![endif]-->
          <div class='outer' style='width:96%;max-width:720px;margin:20px auto;'><div style='width:65ch;text-align:left;'>`;

const outerMailClose = `</div></div>
          <!--[if mso]>
          </td>
          </tr>
          </table>
          <![endif]-->
        </td>
      </tr>
    </table>
  </div>
</body>
</html>`;

const menuBarStyle = {
  display: "flex",
  gap: "1px",
  height: "45px",
  "& button": {
    width: "fit-content",
    p: "6px",
    border: "none",
    backgroundColor: "transparent",
    "&:hover": { bgcolor: "#e7f2ff" },
  },
  "& button:disabled svg": { opacity: 0.5 },
  "& button:first-of-type": { ml: "-10px" },
  "& svg": {
    height: "18px",
    width: "18px",
    color: "rgba(0,0,0,0.77)",
  },
};

const letterToIcon = (letter) => (
  <span
    style={{
      // marginTop: "-8px",
      paddingLeft: "6px",
      fontSize: "1.1rem",
      fontWeight: 500,
    }}
  >
    {letter}
  </span>
);

const MenuBar = ({ editor }) => {
  const allButton = [
    {
      id: "bold",
      icon: <FormatBoldIcon />,
      onClick: () => editor.chain().focus().toggleBold().run(),
      tooltip: "Bold",
    },
    {
      id: "italic",
      icon: <FormatItalicIcon />,
      onClick: () => editor.chain().focus().toggleItalic().run(),
      tooltip: "Italic",
    },
    {
      id: "underline",
      icon: <FormatUnderlinedIcon />,
      onClick: () => editor.chain().focus().toggleUnderline().run(),
      tooltip: "Underline",
    },
    {
      id: "strikethrough",
      icon: <StrikethroughSIcon />,
      onClick: () => editor.chain().focus().toggleStrike().run(),
      tooltip: "Strike through",
    },
    {
      need: false,
      label: (
        <span
          style={{
            // backgroundColor: "red",
            marginTop: "-4px",
            paddingLeft: "6px",
            fontSize: "1.1rem",
            fontWeight: 500,
          }}
        >
          F
        </span>
      ),
    },
    {
      tooltip: "Text type",
      buttonArray: [
        {
          id: "p",
          icon: letterToIcon("P"),
          onClick: () => editor.chain().focus().setParagraph().run(),
        },
        {
          id: "h3",
          icon: letterToIcon("H3"),
          onClick: () =>
            editor.chain().focus().toggleHeading({ level: 3 }).run(),
        },
        {
          id: "h2",
          icon: letterToIcon("H2"),
          onClick: () =>
            editor.chain().focus().toggleHeading({ level: 2 }).run(),
        },
        {
          id: "h1",
          icon: letterToIcon("H1"),
          onClick: () =>
            editor.chain().focus().toggleHeading({ level: 1 }).run(),
        },
      ],
    },
    { need: false, icon: <TextFormatIcon />, onClick: () => {} },
    {
      tooltip: "Align type",
      buttonArray: [
        {
          id: "left",
          icon: <FormatAlignLeftIcon />,
          onClick: () => editor.chain().focus().setTextAlign("left").run(),
        },
        {
          id: "center",
          icon: <FormatAlignCenterIcon />,
          onClick: () => editor.chain().focus().setTextAlign("center").run(),
        },
        {
          id: "right",
          icon: <FormatAlignRightIcon />,
          onClick: () => editor.chain().focus().setTextAlign("right").run(),
        },
      ],
    },
    {
      tooltip: "List type",
      buttonArray: [
        {
          id: "bulletedList",
          icon: <FormatListBulletedIcon />,
          onClick: () => editor.chain().focus().toggleBulletList().run(),
        },
        {
          id: "numberedList",
          icon: <FormatListNumberedIcon />,
          onClick: () => editor.chain().focus().toggleOrderedList().run(),
        },
      ],
    },
    { need: false, dropdown: true },
    { need: false, dropdown: true },
    { need: false, icon: <FormatClearIcon />, onClick: () => {} },
    { need: false },
    { need: false, icon: <GridOnIcon />, onClick: () => {} },
    {
      id: "horizontalRule",
      icon: <RemoveIcon />,
      onClick: () => editor.chain().focus().setHorizontalRule().run(),
      tooltip: "HorizontalRule",
    },
    { need: false, icon: <CodeIcon />, onClick: () => {} },
    { need: false, icon: <Crop32Icon />, onClick: () => {} },
    {
      id: "blockquote",
      icon: <FormatQuoteIcon />,
      onClick: () => editor.chain().focus().toggleBlockquote().run(),
      tooltip: "Blockquote",
    },
    { need: false, label: <SpellcheckIcon /> },
    {
      id: "undo",
      icon: <UndoIcon />,
      onClick: () => editor.chain().focus().undo().run(),
      disabled: !editor.can().chain().focus().undo().run(),
      tooltip: "Undo",
    },
    {
      id: "redo",
      icon: <RedoIcon />,
      onClick: () => editor.chain().focus().redo().run(),
      disabled: !editor.can().chain().focus().redo().run(),
      tooltip: "Redo",
    },
  ];

  if (!editor) {
    return null;
  }

  return (
    <EmailLineContainer
      sx={{ boxShadow: "0 6px 6px 0 rgba(32,33,36,.05)", py: 0 }}
    >
      <Box sx={menuBarStyle}>
        {allButton
          ?.filter((el) => !(el.need === false))
          ?.map(({ id, icon, need, tooltip, buttonArray, ...rest }) => {
            // if (need === false) return null;
            return (
              <>
                {buttonArray ? (
                  <DropdownButton
                    key={id}
                    editor={editor}
                    buttonArray={buttonArray}
                    tooltip={tooltip}
                    {...rest}
                  />
                ) : null}
                {buttonArray ? null : (
                  <Tooltip key={id} title={tooltip} placement="bottom-start">
                    <button type="button" {...rest}>
                      {icon}
                    </button>
                  </Tooltip>
                )}
              </>
            );
          })}
      </Box>
    </EmailLineContainer>
  );
};

export const TipTap = forwardRef(({ content, setEmailContent }, ref) => {
  const editor = useEditor({
    content,
    onUpdate({ editor }) {
      const emailBody = outerMailOpen + editor.getHTML() + outerMailClose;
      editor.getHTML() === "<p></p>"
        ? setEmailContent("")
        : setEmailContent(emailBody);
    },
    extensions: [
      StarterKit,
      Highlight,
      Underline,
      Blockquote,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
    ],
  });

  ref.current = () => {
    editor.commands.setContent("<p></p>");
  };

  return (
    <>
      <Box sx={{ p: "0 1px" }}>
        <MenuBar editor={editor} />
      </Box>
      <Box
        sx={{
          px: "20px",
          py: "4px",
          flexGrow: 1,
          overflowY: "auto",
          "& ul,ol,p,h1,h2,h3": { m: 0 },
          "& div[contenteditable]": { height: "100%" },
          "& div[contenteditable]:focus-visible": { outline: "none" },
          "& blockquote": {
            borderLeft: "3px solid rgb(192, 198, 204)",
            margin: "1.5rem 0",
            paddingLeft: "1rem",
          },
        }}
      >
        <EditorContent editor={editor} style={{ height: "100%" }} />
      </Box>
    </>
  );
});
