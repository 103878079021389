const ZOHO = window.ZOHO;

export async function getInventoryTemplateList({
  module,
  accessToken,
  dataCenterUrl,
}) {
  try {
    const url = `${dataCenterUrl}/crm/v6/settings/inventory_templates`;
    const req_data = {
      params: { module },
      url,
      param_type: 1,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    const templateResp = await ZOHO.CRM.HTTP.get(req_data);

    if (templateResp === "") {
      return {
        data: [],
        error: null,
      };
    }

    const newTemplateResp = await JSON.parse(templateResp);

    return {
      data: newTemplateResp?.inventory_templates,
      error: null,
    };
  } catch (getInventoryTemplateListError) {
    return {
      data: null,
      error: "something went wrong",
    };
  }
}

export const inventory = {
  getInventoryTemplateList,
};
