import * as React from "react";
import Box from "@mui/material/Box";
import { ZohoSelectButtonLight } from "../atoms/ZohoSelectButtonLight";
import { ZohoEmailDropdown } from "../atoms/ZohoEmailDropdown";
import { EmailLineContainer } from "../atoms/EmailLineContainer";
import img from "../../assets/user-thumbnail.png";

const ownerEmail = "Owner's Email";
const defaultEmail = "Default_Email";
const orgEmailType = "Org Email";
const adminEmailType = "Admin Email";
const ownerEmailType = "Record Owner's Email";

export const EmailHeaderLine = ({
  emailTemplates,
  emailTemplatesLoading,
  selectedTemplateLoading,
  fetchSingleEmailTemplate,
  //------
  crmUsersEmail,
  crmUsersEmailLoading,
  //------
  fromEmail,
  setFromEmail,
  //------
  inventoryTemplates,
  inventoryTemplatesLoading,
  inventoryModule,
  selectedInventoryTemplate,
  setSelectedInventoryTemplate,
  //------
  isOwnerEmailSelected,
  setIsOwnerEmailSelected,
  selectedFallbackEmail,
  setSelectedFallbackEmail,
  setEmailType,
  adminEmail,
}) => {
  const newCrmUsersEmail =
    crmUsersEmail?.length > 0
      ? [
          { title: orgEmailType },
          ...crmUsersEmail?.map((el) => ({ email: el, type: orgEmailType })),
        ]
      : [];
  const newOwnerEmail =
    crmUsersEmail?.length > 0
      ? [{ title: ownerEmailType }, { email: ownerEmail, type: ownerEmailType }]
      : [];
  return (
    <EmailLineContainer
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src={img}
          sx={{ height: "28px", width: "28px", borderRadius: "12px" }}
        />
        <ZohoEmailDropdown
          // options={[...crmUsersEmail, ownerEmail]}
          options={[
            ...newCrmUsersEmail,
            { title: adminEmailType },
            { email: adminEmail, type: adminEmailType },
            ...newOwnerEmail,
          ]}
          handleSelectOption={(obj) => {
            if (obj.type === orgEmailType) {
              setEmailType({ type: orgEmailType, orgEmail: true });
            } else {
              setEmailType({ type: obj?.type, orgEmail: false });
            }
            if (obj.email === ownerEmail) {
              setIsOwnerEmailSelected(true);
              setFromEmail(obj.email);
              return;
            }
            setFromEmail(obj.email);
            setIsOwnerEmailSelected(false);
            setSelectedFallbackEmail("");
          }}
          optionsLoading={crmUsersEmailLoading}
          optionLabel={fromEmail ? fromEmail : "Select an email."}
          // handleSelectOption={(email) => {
          //   if (email === ownerEmail) {
          //     setIsOwnerEmailSelected(true);
          //     setFromEmail(email);
          //     return;
          //   }
          //   setFromEmail(email);
          //   setIsOwnerEmailSelected(false);
          //   setSelectedFallbackEmail("");
          // }}
        />
        {isOwnerEmailSelected ? (
          <ZohoEmailDropdown
            options={newCrmUsersEmail}
            handleSelectOption={(obj) => {
              if (obj.type === orgEmailType) {
                setEmailType({ type: orgEmailType, orgEmail: true });
              } else {
                setEmailType({ type: orgEmailType, orgEmail: false });
              }
              setSelectedFallbackEmail(obj.email);
            }}
            // options={crmUsersEmail}
            optionsLoading={false}
            optionLabel={
              selectedFallbackEmail !== ""
                ? selectedFallbackEmail
                : "Select fallback email."
            }
            // handleSelectOption={(email) => {
            //   setSelectedFallbackEmail(email);
            // }}
          />
        ) : null}
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
        }}
      >
        {inventoryModule ? (
          <ZohoSelectButtonLight
            label="Inventory Template"
            list={inventoryTemplates}
            loading={inventoryTemplatesLoading}
            onClick={(option) => {
              setSelectedInventoryTemplate([option]);
            }}
            showSelected={true}
            selectedOptionId={selectedInventoryTemplate?.[0]?.id}
          />
        ) : null}
        <ZohoSelectButtonLight
          label="Insert Template"
          list={emailTemplates}
          loading={emailTemplatesLoading || selectedTemplateLoading}
          onClick={(option) => {
            fetchSingleEmailTemplate(option.id);
          }}
        />
      </Box>
    </EmailLineContainer>
  );
};
