const ZOHO = window.ZOHO;

export async function getMailmergeTemplateList({
  module,
  accessToken,
  dataCenterUrl,
}) {
  try {
    const url = `${dataCenterUrl}/crm/v2.1/settings/templates?type=mailmerge&module=${module}`;
    var req_data = {
      url,
      param_type: 1,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    const getAttachmentsResp = await ZOHO.CRM.HTTP.get(req_data);
    const resp = await JSON.parse(getAttachmentsResp);

    const mailmerge_templates = resp?.templates;

    const mailmerge_names = mailmerge_templates?.map(
      (obj) => obj.name.split(".zdoc")[0]
    );

    return {
      data: mailmerge_names,
      error: null,
    };
  } catch (getMailmergesTemplateListError) {
    return {
      data: null,
      error: "something went wrong",
    };
  }
}

export async function mailmergeToAttachmentsByDeluge({
  module,
  recordId,
  templateName,
  storageModule,
  storageRecordId,
  dataCenterUrl,
  accessToken,
}) {
  try {
    var func_name = "easyemailforzohocrm__save_template_to_record";
    var req_data = {
      arguments: JSON.stringify({
        query: {
          module,
          recordId,
          templateName,
          storageModule,
          storageRecordId,
          dataCenterUrl,
          accessToken: "Bearer " + accessToken,
        },
      }),
    };
    const resp = await ZOHO.CRM.FUNCTIONS.execute(func_name, req_data);

    return {
      data: resp?.data,
      error: null,
    };
  } catch (mailmergeToAttachmentsByDelugeErroe) {
    return {
      data: null,
      error: "Something went wrong.",
    };
  }
}

export const mailmerge = {
  getMailmergeTemplateList,
  mailmergeToAttachmentsByDeluge,
};
