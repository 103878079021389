import * as React from "react";
import Box from "@mui/material/Box";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { SnackbarProvider, MaterialDesignContent } from "notistack";
import CssBaseline from "@mui/material/CssBaseline";
import { SettingsRoute } from "./Pages/SettingsRoute";
import { ButtonRoute } from "./Pages/ButtonRoute";
import { CustomActionRoute } from "./Pages/CustomActionRoute";

// import RelatedList from "./Pages/RelatedList";
// import Webtab from "./Pages/Webtab";
// import Businesscard from "./Pages/Businesscard";
// import { useZohoAuth } from "./hook/useZohoAuth";

// const ProtectRoute = ({ Component }) => {
//   const authResp = useZohoAuth({
//     height: "90%",
//     width: "90%",
//   });

//   if (authResp.authLoadingZoho) {
//     return <>Auth checking...</>;
//   }

//   if (!authResp.authLoadingZoho && !authResp.zohoAuthenticated) {
//     return <>Not authenticated, contact with support.</>;
//   }

//   return <Component {...authResp} />;
// };

function App() {
  const router = createBrowserRouter([
    {
      path: "/settings",
      element: <SettingsRoute />,
    },
    {
      path: "/buttons",
      element: <ButtonRoute />,
      // element: <ProtectRoute Component={ButtonRoute} />,
    },
    {
      path: "/customaction",
      element: <CustomActionRoute />,
    },
    // {
    //   path: "/relatedlist",
    //   element: <RelatedList />,
    // },
    // {
    //   path: "/webtab",
    //   element: <Webtab />,
    // },
    // {
    //   path: "/businesscard",
    //   element: <Businesscard />,
    // },
  ]);
  return <RouterProvider router={router} />;
}

const Base = () => {
  return (
    <React.Fragment>
      <SnackbarProvider>
        <CssBaseline />
        <App />
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default Base;
