import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

export const ZohoCTAButton = ({ children, loading, ...rest }) => {
  return (
    <Button
      variant="contained"
      size="small"
      sx={{
        bgcolor: "#2d8eee",
        boxShadow: "inset 0 -3px 4px  #0779cc",
        fontSize: "medium",
        fontWeight: "bold",
        textTransform: "capitalize",
        backgroundImage:
          "transparent linear-gradient(0deg, #0279FF 0%, #00A3F3 100%)",
        py: 0.3,
        px: "10px",
        "&:hover": {
          backgroundImage: "linear-gradient(to top,#0061CA, #2fa6ea)",
          bgcolor: "#2d8eee",
          boxShadow: "none",
        },
        position: "relative",
      }}
      {...rest}
    >
      {loading ? (
        <Box
          component="span"
          sx={{
            position: "absolute",
            inset: 0,
            display: "grid",
            placeItems: "center",
          }}
        >
          <CircularProgress size={17} />
        </Box>
      ) : null}
      {children}
    </Button>
  );
};
