import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DoneIcon from "@mui/icons-material/Done";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";

const buttonGroupStyle = {
  height: "28px",
  // bgcolor: "#EDF6FF",
  borderRadius: "6px",
  boxShadow: "none",
  outline: "1px solid #338CF0",
  "&:hover": { boxShadow: "none" },
  "& > button": {
    bgcolor: "#EDF6FF",
    color: "#338CF0",
    textTransform: "capitalize",
    borderRadius: "6px",
  },
  "& > button:hover": { bgcolor: "#E2F0FF" },
};

export function ZohoSelectButtonLight({
  label,
  list,
  loading,
  onClick,
  selectedOptionId,
  showSelected = false,
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const showCheck = showSelected && !!selectedOptionId;
  const handleMenuItemClick = (event, index, option) => {
    onClick(option);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
        disableRipple
        sx={buttonGroupStyle}
        disabled={list?.length < 1 || loading}
      >
        <Button onClick={handleToggle}>{label}</Button>
        <Button
          size="small"
          sx={{ width: "44px" }}
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          {loading ? <CircularProgress size={12} /> : null}
          {showCheck ? <DoneIcon size={6} /> : <ArrowDropDownIcon />}
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {list.map((option, index) => {
                    let itemSelected = selectedOptionId === option.id;

                    return (
                      <MenuItem
                        key={option.id}
                        // disabled={index === 2}
                        // selected={index === selectedIndex}
                        onClick={(event) =>
                          handleMenuItemClick(event, index, option)
                        }
                      >
                        {showSelected ? (
                          <Checkbox
                            sx={{ py: 0, pl: 0 }}
                            {...{
                              inputProps: { "aria-label": "Item checkbox" },
                            }}
                            checked={itemSelected}
                            disableRipple
                            size="small"
                          />
                        ) : null}

                        {option.name}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
