import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

export function PopDialog({
  loading,
  selectedType,
  isDialogOpen,
  handleCloseDialog,
  itemList,
  handleSelectItem,
  selectedItemList,
  setSearchInput,
  noDataMessage,
}) {
  let [selectedArray, setSelectedArray] = React.useState([]);
  React.useEffect(() => {
    setSelectedArray(selectedItemList);
  }, [selectedItemList]);

  return (
    <Dialog
      disableRestoreFocus
      onClose={() => {
        setSelectedArray([]);
        handleCloseDialog();
      }}
      aria-labelledby="customized-dialog-title"
      open={isDialogOpen}
      scroll="paper"
      sx={{
        "& .MuiDialog-paper": { width: "50%" },
      }}
    >
      <DialogTitle
        id="customized-dialog-title"
        sx={{
          px: "1.25rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {selectedType?.title}
        <TextField
          autoFocus
          id="input-with-icon-textfield"
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          size="small"
          variant="outlined"
        />
      </DialogTitle>
      <DialogContent dividers sx={{ p: 0, height: "15rem" }}>
        {loading ? (
          <CircularProgress
            size={20}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        ) : null}
        {!loading && itemList?.length === 0 ? (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {noDataMessage}
          </Box>
        ) : null}
        {!loading && itemList?.length > 0 ? (
          <MenuList id="split-button-menu" sx={{ p: 0 }}>
            {itemList?.map((option, index) => {
              const selectKey = option.api_name ? "api_name" : "id";
              return (
                <MenuItem
                  key={option.id}
                  onClick={() => {
                    setSelectedArray((pre) => {
                      let preValues = pre?.map((el) => el[selectKey]);
                      let curValue = option[selectKey];
                      let newOpt;
                      if (selectKey === "api_name") {
                        newOpt = itemList?.filter(
                          (el) => el[selectKey] === curValue
                        );
                      } else {
                        newOpt = [option];
                      }
                      if (preValues.includes(curValue)) {
                        return pre.filter((el) => el[selectKey] !== curValue);
                      }
                      if (selectedType?.type === "inventoryTemplate") {
                        return newOpt;
                      }
                      return [...pre, ...newOpt];
                      // return [...pre, option];
                    });
                  }}
                  disableRipple
                  size="small"
                >
                  <Checkbox
                    sx={{ py: 0, pl: 0 }}
                    {...{ inputProps: { "aria-label": "Item checkbox" } }}
                    checked={selectedArray
                      ?.map((el) => el[selectKey])
                      .includes(option[selectKey])}
                    disableRipple
                    size="small"
                  />
                  <Box
                    component="span"
                    sx={{ width: "35%", display: "inline-block" }}
                  >
                    {option.name}
                  </Box>
                </MenuItem>
              );
            })}
          </MenuList>
        ) : null}
      </DialogContent>
      <DialogActions sx={{ m: 0, px: "24px" }}>
        <Button
          sx={{ textTransform: "capitalize" }}
          variant="outlined"
          onClick={() => {
            setSelectedArray([]);
            handleCloseDialog();
          }}
        >
          Close
        </Button>
        <Button
          sx={{ textTransform: "capitalize" }}
          variant="contained"
          disabled={selectedArray?.length < 1 && selectedItemList?.length === 0}
          // disabled={selectedArray?.length < 1}
          onClick={() => {
            handleSelectItem({ type: selectedType?.type, arr: selectedArray });
            setSelectedArray([]);
            handleCloseDialog();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
