import * as React from "react";
import Button from "@mui/material/Button";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { styled } from "@mui/material/styles";

// https://codesandbox.io/p/sandbox/react-mui-forked-5irby7
// https://www.robinwieruch.de/react-nested-dropdown-material-ui-mui/

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const paperStyle = {
  boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
  // maxHeight: "14rem",
  overflowY: "auto",
};

const popperStyle = {
  zIndex: 10000,
  pb: "2rem",
};

const menuItemStyle = {
  color: "#313949",
  fontSize: ".9rem",
  "&:hover": { bgcolor: "#e7f2ff" },
  "& .MuiButton-icon": { mr: 0 },
};

const buttonStyle = {
  width: "100%",
  justifyContent: "flex-start",
  textTransform: "none",
  boxShadow: "none",
  bgcolor: "transparent",
  color: "initial",
  "&:hover": {
    boxShadow: "none",
    bgcolor: "transparent",
  },
};

export const Pop = ({
  anchorRef,
  openPop,
  handleClosePop,
  buttonList,
  handleSelectFile,
}) => {
  return (
    <Popper
      sx={popperStyle}
      placement="top-start"
      open={openPop}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: "bottom" }}>
          <Paper sx={paperStyle}>
            <ClickAwayListener onClickAway={handleClosePop}>
              <MenuList id="split-button-menu" autoFocusItem>
                {buttonList.map((button, index) => {
                  if (button.show) {
                    return (
                      <MenuItem
                        sx={menuItemStyle}
                        key={button.label}
                        disableRipple
                      >
                        <Button
                          disableRipple
                          sx={buttonStyle}
                          component={button?.file ? "label" : null}
                          role={undefined}
                          variant="contained"
                          tabIndex={-1}
                          startIcon={button?.icon}
                          onClick={button?.file ? null : button?.optionClick}
                          type="button"
                        >
                          {button?.typeObj.title}
                          {button?.file ? (
                            <VisuallyHiddenInput
                              type="file"
                              multiple
                              onChange={handleSelectFile}
                            />
                          ) : null}
                        </Button>
                      </MenuItem>
                    );
                  }
                })}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
