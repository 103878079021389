import * as React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import CreateIcon from "@mui/icons-material/Create";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";

import { EmailLineContainer } from "../atoms/EmailLineContainer";

export const EmailSubjectLine = ({
  sx,
  richTextEditor,
  onSelectRichTextEditor,
  ...rest
}) => {
  return (
    <EmailLineContainer
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <TextField
        name="subject"
        placeholder="Subject"
        id="input-with-icon-textfield"
        sx={{
          width: "100%",
          "& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: 0,
          },
          "& .MuiInput-root::before": {
            borderBottom: 0,
          },
          "& .MuiInput-root::after": {
            borderBottom: 0,
          },
          ...sx,
        }}
        inputProps={{
          sx: {
            "&::placeholder": {
              color: "rgba(0,0,0,0.67)",
              opacity: 1, // otherwise firefox shows a lighter color
            },
          },
        }}
        {...rest}
        variant="standard"
        InputProps={{
          endAdornment: (
            <Tooltip title={"Change editor"}>
              <span>
                <Button
                  disabled={richTextEditor}
                  onClick={onSelectRichTextEditor}
                  sx={{
                    p: 0,
                    minWidth: 0,
                  }}
                  endIcon={<RotateLeftIcon />}
                  disableRipple
                />
              </span>
            </Tooltip>
          ),
        }}
      />
    </EmailLineContainer>
  );
};
