import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { EmailLineContainer } from "../atoms/EmailLineContainer";
import { MultiInputTextField } from "../atoms/MultiInputTextfield";

export const EmailToLine = ({
  freeSolo,
  handleOpenBcc,
  handleOpenCc,
  options,
  array,
  setArray,
  cc,
  bcc,
  checkEmail,
}) => {
  return (
    <EmailLineContainer
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <MultiInputTextField
        freeSolo={freeSolo}
        options={options}
        array={array}
        setArray={setArray}
        name={"to"}
        label={"To"}
        to={true}
        handleClose={() => {}}
        checkEmail={checkEmail}
      />

      <Box sx={{ display: "flex" }}>
        {bcc ? null : (
          <Button
            disableRipple
            sx={{ p: 0, minWidth: 0 }}
            onClick={handleOpenBcc}
          >
            Bcc
          </Button>
        )}
        {cc ? null : (
          <Button
            disableRipple
            sx={{ p: 0, minWidth: 0, ml: "8px" }}
            onClick={handleOpenCc}
          >
            Cc
          </Button>
        )}
      </Box>
    </EmailLineContainer>
  );
};
