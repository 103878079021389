import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Typography from "@mui/material/Typography";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import CircularProgress from "@mui/material/CircularProgress";

export function ZohoEmailDropdown({
  options,
  optionsLoading,
  optionLabel,
  handleSelectOption,
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleMenuItemClick = (event, index, email) => {
    handleSelectOption(email);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
        disableRipple
        sx={{
          boxShadow: "none",
          "&:hover": { boxShadow: "none" },
          "& > button": {
            bgcolor: "white",
            color: "currentColor",
            textTransform: "capitalize",
          },
          "& > button:hover": { bgcolor: "white" },
        }}
      >
        <Button
          disabled={optionsLoading}
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <Typography
            name="fromEmail"
            sx={{
              opacity: 0.7,
              minWidth: "6rem",
              textTransform: "none",
              mr: ".5em",
            }}
          >
            {optionLabel}
          </Typography>
          {optionsLoading ? (
            <CircularProgress size={12} />
          ) : (
            <ArrowDropDownIcon />
          )}
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement={"bottom-start"}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options?.map((option, index) => {
                    if (option.title) {
                      return (
                        <MenuItem
                          disableRipple
                          key={option.title}
                          sx={{ bgcolor: "rgba(239, 239, 240,1)", pl: "8px" }}
                        >
                          {option?.title}
                        </MenuItem>
                      );
                    }
                    return (
                      <MenuItem
                        sx={{ textTransform: "none" }}
                        key={option.email + option.type}
                        // key={option}
                        // disabled={index === 2}
                        // selected={index === selectedIndex}
                        onClick={(event) =>
                          handleMenuItemClick(event, index, option)
                        }
                      >
                        {/* {option} */}
                        {option?.email}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
