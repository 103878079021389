import * as React from "react";
import Box from "@mui/material/Box";
import { Dialog as MUIDialog } from "@mui/material/";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide ref={ref} {...props} timeout={700} />;
});

export function Dialog({ dialogOpen, handleDialogClose, children }) {
  return (
    <MUIDialog
      fullScreen
      open={dialogOpen}
      onClose={handleDialogClose}
      // TransitionComponent={Transition}
    >
      {children}
    </MUIDialog>
  );
}
{
  /* <Box sx={{ overflow: "hidden", bgcolor: "red" }}>{children}</Box> */
}
