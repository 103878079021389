import * as React from "react";
import { EmailLineContainer } from "../atoms/EmailLineContainer";
import { MultiInputTextField } from "../atoms/MultiInputTextfield";

export const EmailCcLine = ({
  freeSolo,
  handleCloseCc,
  options,
  array,
  setArray,
}) => {
  return (
    <EmailLineContainer
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <MultiInputTextField
        name={"cc"}
        label={"Cc"}
        freeSolo={freeSolo}
        options={options}
        array={array}
        setArray={setArray}
        handleClose={handleCloseCc}
      />
    </EmailLineContainer>
  );
};
