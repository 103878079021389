import * as React from "react";
import { EmailLineContainer } from "../atoms/EmailLineContainer";
import { MultiInputTextField } from "../atoms/MultiInputTextfield";

export const EmailBccLine = ({
  freeSolo,
  array,
  setArray,
  options,
  handleCloseBcc,
}) => { 
  return (
    <EmailLineContainer
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <MultiInputTextField
        freeSolo={freeSolo}
        options={options}
        array={array}
        setArray={setArray}
        name={"bcc"}
        label={"Bcc"}
        handleClose={handleCloseBcc}
      />
    </EmailLineContainer>
  );
};
